import React from "react"
import IntroBox from "../../components/intro-box"
import BasicLayout from "../../layouts/basic-layout"
import { Image } from "react-bootstrap"
import styled from "styled-components"

const desc =
  "云南铂峰绿化工程有限公司成立于2012年8月，是一家专业从事景观设计施工及管养的绿化公司。作为云南城建物业集团有限公司全资控股公司注册资金200万，目前拥有专业项目管理团队18人，高级工程师2人，绿化养护工120人。由负责城建物业所有服务区域的绿化管养开始发展，企业企业发展至今有着十多年的绿化管理经验，经过对工程施工和创意设计的探索突破，逐步锻炼出一支锐意进取，勇于突破的团队。通过完成元阳艺术家村商业街室外景观项目、大理苍山国际度假村二期、云南映象城市公园广场室外景观绿化工程等项目，经过工程实践，在磨砺了铂峰绿化优秀的创作团队的同时，也塑造了铂峰绿化新锐独到的设计理念以及严密科学的景观营造体系，更积累了丰富的景观营造资源。"

const Container = styled.div`
  .header {
    height: 60px;
    line-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
      height: 60px;
      width: 60px;
    }
    span {
      margin-left: 20px;
      font-size: 36px;
      color: #807F5E;
    }
  }

  .wrapper1 {
    width: 100%;
    background: #ddd;
    padding: 30px 0;
    color: #333;
  }

  .wrapper2 {
    max-width: 1200px;
    margin: 30px auto;

    img {
      width: 100%;
      height: 100%;
      margin-bottom: 30px;
    }
  }
`

const Page = () => (
  <BasicLayout
    width="100%"
    title="绿化工程"
    bannerImg="http://maps.yncce.cn/website/lhgc.png"
  >
    <Container>
      <div className="header">
        <img src="http://cdn.jerryshi.com/gtkj/20200109095047.png" alt="zj"/>
        <span>昆明城建梦居装饰有限公司</span>
      </div>
      <div className="wrapper1">
        <IntroBox
          desc={desc}
          invert={true}
          height="23rem"
          width="1200px"
          border="1px solid #999"
          img="http://maps.yncce.cn/website/zszx-b0.png"
        />
      </div>
      <div className="wrapper2">
        <Image src="http://maps.yncce.cn/website/zszx-b1.png" fluid />
        <Image src="http://maps.yncce.cn/website/zszx-b2.png" fluid />
        <Image src="http://maps.yncce.cn/website/zszx-b3.png" fluid />
      </div>
    </Container>
  </BasicLayout>
)

export default Page
